import React from 'react';

import Modal from '@mui/material/Modal';

import { useResponsive } from '../hooks/useResponsive';
import SurveyFormContainer  from '../containers/SurveyFormContainer';

export const SurveyContainer = ({surveyShow}) => {
  const { isMobile } = useResponsive();

  const style = {
    modalStyle: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      top: 'unset',
      bottom: 'unset',
      left: 'unset',
      right: 'unset'
    }
  };
  return (
    <React.Fragment>
      {isMobile ? (
        <React.Fragment>
          {surveyShow && (
            <SurveyFormContainer />
          )}
        </React.Fragment>
      ) : (
        <Modal open={surveyShow} sx={style.modalStyle} disablePortal>
          <React.Fragment>
            <SurveyFormContainer />
          </React.Fragment>
        </Modal>
      )}
    </React.Fragment>
  );
};