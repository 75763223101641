import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    openSummary: (state) => {
      state.open = true;
    },
    closeSummary: (state) => {
      state.open = false;
    },
  }
});

export const { 
  openSummary, 
  closeSummary, 
  showOptionsList,  
  hideOptionsList,
  setTypeSearch
} = summarySlice.actions;

export default summarySlice.reducer;