const prod = {
  city: process.env.REACT_APP_CITY,
  url: {
    PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    TILE_SERVER_URL: process.env.REACT_APP_TILE_SERVER_URL,
    GEOCODER_URL: process.env.REACT_APP_GEOCODER_URL,
    MAPILLARY_URL: process.env.REACT_APP_MAPILLARY_URL,
    GRAPH_MAPILLARY_URL: process.env.REACT_APP_GRAPH_MAPILLARY_URL
  },
  token: {
    MAPTILER_KEY: process.env.REACT_APP_MAPTILER_KEY
  }
};
   
const dev = {
  city: process.env.REACT_APP_CITY,
  url: {
    PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL || 'http://localhost:3000',
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8008/api',
    GEOCODER_URL: process.env.REACT_APP_GEOCODER_URL,
    TILE_SERVER_URL: process.env.REACT_APP_TILE_SERVER_URL,
    MAPILLARY_URL: process.env.REACT_APP_MAPILLARY_URL,
    GRAPH_MAPILLARY_URL: process.env.REACT_APP_GRAPH_MAPILLARY_URL
  },
  token: {
    MAPTILER_KEY: process.env.REACT_APP_MAPTILER_KEY
  }
};
  
export const config = process.env.REACT_APP_NODE_ENV === 'development' ? dev : prod;