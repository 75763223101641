import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  type: 'info',
  content: ''
};

const notiSlice = createSlice({
  name: 'noti',
  initialState,
  reducers: {
    openNoti: (state) => {
      state.open = true;
    },
    setTypeNoti: (state, action) => {
      state.type = action.payload;
    },
    setContentNoti: (state, action) => {
      state.content = action.payload;
    },
    closeNoti: (state) => {
      state.open = false;
      state.content = '';
    }
  }
}); 

export const { openNoti, setTypeNoti, setContentNoti, closeNoti } = notiSlice.actions;

export default notiSlice.reducer;