import React, { useEffect } from 'react';

import { Source, Layer, useMap } from 'react-map-gl/maplibre';
import { useSelector } from 'react-redux';

export const MapEvaluatedLayers = ({data}) => {
  const {map} = useMap();
  const showSearch = useSelector(state => state.search.open);
  const showPoi = useSelector(state => state.poi.open);
  const primaryColor = useSelector(state => state.init.primary_color);

  const evaluatedEdgesStyle = {
    id: 'evaluated-edges',
    source: 'evaluated-edges',
    type: 'line',
    layout: {
      'line-cap': 'round'
    },
    paint: {
      'line-width': 10,
      'line-color': `${primaryColor}`,
      'line-opacity': 0.4
    }
  };

  /**
   * load style visibility
   */
  useEffect(() => {
    if (showSearch || showPoi) {
      map.getMap().setLayoutProperty('evaluated-edges', 'visibility', 'visible');
    } else {
      map.getMap().setLayoutProperty('evaluated-edges', 'visibility', 'none');
    }
  }, [showSearch, showPoi]);

  return (
    <Source id='evaluated-edges' generateId={true} type='geojson' data={data} >
      <Layer id='evaluated-edges' {...evaluatedEdgesStyle} />
    </Source> 
  );
};