import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limitBounding: null,
  center: null,
  polygons: null,
  layers: null,
  tileservers: null,
  zones: [],
  interact: false,
  resetSelected: false,
  streetToPoint : false,
  pointStreet: [],
  addStreet: false
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    startResetSelected: (state) => {
      state.resetSelected = true;
    },
    stopResetSelected: (state) => {
      state.resetSelected = false;
    },
    setCenter: (state, action) => {
      state.center = action.payload;
    },
    setLimitBoundings: (state, action) => {
      state.limitBounding = action.payload;
    },
    setTileServ: (state, action) => {
      state.tileservers = action.payload;
    },
    setTileLayer: (state, action) => {
      state.layers = action.payload;
    },
    setPolygonsCity: (state, action) => {
      state.polygons = action.payload;
    },
    setZonesOfCity: (state, action) => {
      state.zones = action.payload;
    },
    setInteractMap: (state, action) => {
      state.interact = action.payload;
    },
    setAddStreet: (state, action) => {
      state.addStreet = action.payload;
    },
    setStreetToPoint: (state, action) => {
      state.streetToPoint = true;
      state.pointStreet = action.payload;
    },
    resetPointStreet: (state) => {
      state.streetToPoint = false;
      state.pointStreet = [];
    }
  }
});

export const {
  startResetSelected, 
  stopResetSelected,
  setCenter,
  setLimitBoundings,
  setAddStreet, 
  setTileServ,
  setTileLayer,
  setPolygonsCity,
  setZonesOfCity,
  setInteractMap,
  setStreetToPoint, 
  resetPointStreet
} = mapSlice.actions;

export default mapSlice.reducer;