import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { InfoSurveyByUser } from '../components/InfoSurveyByUser';
import { openSurvey, setSurvey, setSurveyId, setTypeSurvey } from '../slices/surveySlice';
import { callGetWayJson } from '../api/FeatureApi';
import { setLabelPoi } from '../slices/poiSlice';

export const UserInfoContainer = ({open, onClose}) => {

  const dispatch = useDispatch();
  
  const userListSurveys = useSelector(state => state.user.surveyAnswers);
  const [listSurveysWithNames, setListSurveyWithNames] = useState([]);

  const handleOpenSurvey = (id, label) => {
    const surveySelected = userListSurveys.find(elem => elem.id === id);
    dispatch(setSurveyId(surveySelected.id));
    dispatch(setSurvey(surveySelected));
    dispatch(setLabelPoi(label));
    dispatch(setTypeSurvey('update'));
    dispatch(openSurvey());
  };

  const fetchDataWaysWithName = async () => {
    const response = Promise.all(userListSurveys.map(elem => {
      if (elem !== undefined && elem.ways.length > 0) {
        return callGetWayJson(elem.ways[0]).then(results => {
          return {survey: elem.id, name: results.name};
        });
      }
    }));
    return response;
  };

  useEffect(() => {
    fetchDataWaysWithName().then(results => {
      const sortedList = results.filter(res => res !== undefined).sort((a, b) => {
        return a.survey - b.survey;
      });
      setListSurveyWithNames(sortedList);
    });
  }, [userListSurveys]);

  return (
    <div className={`user-menu ${open && 'active'} `}>
      <div className='header-user-info'>
        <div className='icon-rank'>
          <span className={'chipicon icon-star'} />
        </div>
        <button className="btn-close" onClick={onClose}>
          <span className="icon-times"></span>
        </button>
      </div>
      <div className='user-info'>
        <InfoSurveyByUser showModify={true} />
      </div>
      <div className='list-survey'>
        <div className="heading">Vous avez donné votre avis sur: </div>
        <div className='list'>
          {listSurveysWithNames.map((elem, index) => (
            <div className='survey-info' key={index}>
              <div className='label-eval'>
                <span className={'icon icon-pin'} 
                  style={{marginRight: 16, fontSize: 24}} />
                <span>{elem.name === '' ? 'Cheminement sans nom' : elem.name}</span>
              </div>
              <button className='btn-update' onClick={() => handleOpenSurvey(elem.survey, elem.name)}>
                Modifer
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};