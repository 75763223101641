import React, { createRef, memo, useEffect } from 'react';

import { Viewer } from 'mapillary-js';

const mapillary_access_token = 'MLY|6775241895922391|0d8bb0fe1626b0804a775875b9f30518';

const testsAreEquals = (prevRoad, nextRoad) => {
  if (prevRoad.idView !== nextRoad.idView) {
    return false;
  }
  return true;
};

const StreetViewContainer = ({idView}) => {

  const streetViewRef = createRef();

  useEffect(() => {
    const viewer = new Viewer({
      accessToken: mapillary_access_token,
      container: streetViewRef.current,
      imageId: idView,
      component: {
        cover: true
      }
    });
    return () => {
      viewer.remove();
    };
  }, [idView]);

  return (
    <div 
      className='street-view' 
      ref={streetViewRef} 
    />
  ); 
};

export const StreetView = memo(StreetViewContainer, testsAreEquals);