import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Modal  from '@mui/material/Modal';

import { ForwardButton } from '../components/Button/ForwardButton';
import { closeSummary } from '../slices/summarySlice';
import { openSearch } from '../slices/searchSlice';
import { useResponsive } from '../hooks/useResponsive';
import { resetPoi } from '../slices/poiSlice';
import { setRefetch } from '../slices/initSlice';
import { InfoSurveyByUser } from '../components/InfoSurveyByUser';
import { config } from '../config';

export const SummarySurveyContainer = () => {
  const dispatch = useDispatch();
  const {isMobile} = useResponsive();
  const style = {
    modalStyle: {
      '&.MuiModal-root': {
        top: isMobile ? 'unset' : 0 
      }
    }
  };

  const summarySurveyShow = useSelector(state => state.summary.open);


  const handleRevaluate = () => {
    dispatch(resetPoi());
    dispatch(setRefetch(true));
    dispatch(closeSummary());
    dispatch(openSearch());
  };
  return (
    <Modal 
      open={summarySurveyShow} 
      sx={style.modalStyle} 
      onClose={handleRevaluate}
      disablePortal
    >
      <div className="overlay flow-half">
        <img src={`${config.url.API_BASE_URL}/logo`} alt="logo ville" />
        <p>Un grand merci pour votre participation ! 🙌</p>
        <div>
          <InfoSurveyByUser />
        </div>
        <ForwardButton label='Évaluer une autre rue' handleClick={handleRevaluate} /> 
      </div>
    </Modal>
  );
};