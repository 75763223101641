export const listCriteria = [
  
  // Practicability
  {
    'id':'sidewalks',
    'label':'Trottoirs',
    'details':'Est-ce que cette rue comportent des trottoirs ? Si oui, vous semblent-ils praticables ? Sont-ils larges, sans obstacles, avec un revêtement plat ?',
    'theme':'practicability'
  },
  {
    'id':'crossings',
    'label':'Traversées',
    'details':'Est-ce que les traversées vous semblent praticables ? Est-ce que la traversée est facile ?',
    'theme':'practicability'
  },
  {
    'id':'obstruction',
    'label':'Espace pour marcher',
    'details':"De façon générale, est-ce qu'il y a suffisamment de place pour marcher ? Autrement dit, les trottoirs ou le centre de la rue/chemin sont-ils généralement dégagés ?",
    'theme':'practicability'
  },
  {
    'id':'accessibility',
    'label':'Accessibilité',
    'details':"Est-ce que l'accessibilité pour les personnes en situation de handicap moteur ou visuel vous semble bonne ?",
    'theme':'practicability'
  },
  {
    'id':'slope',
    'label':'Pentes',
    'details':'Est-ce que la pente vous semblent praticable ?',
    'theme':'practicability'
  },
  {
    'id':'lighting',
    'label':'Éclairage',
    'details':"Est-ce que l'éclairage noctune vous semble suffisant ?",
    'theme':'practicability'
  },
  
  // Comfort
  
  {
    'id':'aesthetic',
    'label':'Esthétique',
    'details':"Appréciez-vous l'esthétique de cette rue/chemin ? Son architecture",
    'theme':'comfort'
  },
  {
    'id':'vegetation',
    'label':'Végétation',
    'details':'Appréciez-vous la végétation de cette rue/chemin ?',
    'theme':'comfort'
  },
  {
    'id':'cleanness',
    'label':'Propreté',
    'details':'Appréciez-vous la propreté de cette rue/chemin ?',
    'theme':'comfort'
  },
  {
    'id':'noise',
    'label':'Calme',
    'details': 'De façon générale, appréciez-vous le calme de cette rue/chemin, est-elle sans nuisances sonores ?',
    'theme':'comfort'
  },
  {
    'id':'shops',
    'label':'Commerces',
    'details': "Est-ce que cette rue/chemin comporte des commerces qui vous donnent envie de l'emprunter ?",
    'theme':'comfort'
  },
  {
    'id':'stops',
    'label':'Arrêts de transport',
    'details': "Est-ce que cette rue/chemin vous permet d'accéder à des arrêts de transport public ?",
    'theme':'comfort'
  },  
  {
    'id':'seat',
    'label':'Possibilité de s’asseoir',
    'details': "Appréciez-vous la possibilité de s'asseoir dans cette rue/chemin, ou au contraire est-ce dommage de ne pas pouvoir le faire ?",
    'theme':'comfort'
  },  
  {
    'id':'crowded',
    'label':'Affluence',
    'details':"Appréciez-vous l'affluence dans cette rue, ou est-elle au contraire souvent problématique ?",
    'theme':'comfort'
  },
  {
    'id':'cars',
    'label':'Cohabitation avec les automobilistes',
    'details':'Est-ce que la cohabitation avec les voitures et le trafic est bonne ?',
    'theme':'security'
  },
  {
    'id':'bikes',
    'label':'Cohabitation avec les vélos/trottinettes',
    'details':'Est-ce que la cohabitation avec vélos et trotinettes est bonne ?',
    'theme':'security'
  },
  {
    'id':'safety',
    'label':'Lieu sûr',
    'details':'Est-ce que votre sentiment de sûreté est bon ?',
    'theme':'security'
  },
  
];