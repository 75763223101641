import React from 'react';

export const CommentContainer = ({register}) => {

  return (
    <div className="form-group form-textarea">
      <label htmlFor="comments">Laisser un commentaire</label>
      <div className="form-input">
        <textarea 
          className="form-text" 
          name="comments" 
          id="comments" 
          maxLength={200} 
          {...register('comment')}
        />
      </div>
      <div className="form-tooltip">
        <small>200 caractères max</small>
      </div>
    </div>
  );
};