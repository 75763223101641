import { config } from '../config';

export async function callGetWayJson(id) {
  let headers = new Headers();
  const url = `${config.url.API_BASE_URL}/ways/${id}`;
  const response = await fetch(url, {
    method:'GET',
    headers: headers,
    cache: 'no-cache'
  }).then(response => response.json()).catch(error => {
    console.log('error fetch feature api :', error);
  });
  return response;
}

export async function callGetWayJsonByName (q) {
  let headers = new Headers();
  const url = `${config.url.API_BASE_URL}/ways/?filter_name=${q}`;
  const response = await fetch(url, {
    method:'GET',
    headers: headers,
    cache: 'no-cache'
  }).then(response => response.json()).catch(error => {
    console.log('error fetch feature by name api :', error);
  });
  return response;
}

export async function callGetWayJsonByCoord (lng, lat) {
  let headers = new Headers();
  const url = `${config.url.API_BASE_URL}/ways/${lng}/${lat}`;
  const response = await fetch(url, {
    method:'GET',
    headers: headers,
    cache: 'no-cache'
  }).then(response => response.json()).catch(error => {
    console.log('error fetch feature by coordinates api :', error);
  });
  return response;
}