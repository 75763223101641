import React from 'react';


export const CriteriaCard= ({label, details, value, handlePos, handleNev, handleReset}) => {
  const setPosValue = (e) => {
    e.preventDefault();
    handlePos();
  }; 

  const setNegValue = e => {
    e.preventDefault();
    handleNev();
  };

  const resetValue = e => {
    e.preventDefault();
    handleReset();
  };

  return (
    <>
      <details>
        <summary>
          <div className='heading'>
            <span className='heading-label'>
              {label}
            </span>
          </div>
        </summary>
        <span>
          {details}
        </span>
      </details>
      <div className="answers">
        <button 
          className={`positive ${(value &&  value !== null) ? 'active' : null}`} 
          onClick={(value && value !== null ) ? resetValue : setPosValue}
        >
          <span className="icon icon-happy-face"></span>
        </button>
        <button 
          className={`negative ${(!value && value !== null) ? 'active' : null}`}
          onClick={(!value && value !== null ) ? resetValue : setNegValue}
        >
          <span className="icon icon-sad-face"></span>
        </button>
      </div>
    </>
  );
};