export const getRankBySurveys = (nbSurveys) => {
  if (nbSurveys >= 10) {
    return 3;
  } else if (nbSurveys >= 5) {
    return 2;
  } else {
    return 1;
  }
};

export const surveyRestFromChange = (nbSurveys) => {
  const rankOfUser = getRankBySurveys(nbSurveys);
  switch (rankOfUser) {
    case 1:
      return 5 - nbSurveys;
    case 2: 
      return 10 - nbSurveys;
    default:
      return 0;
  }
};
