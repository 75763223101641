import React from 'react';

import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';

import { useResponsive } from '../hooks/useResponsive';

export const AlertNoti = ({closeCallback}) => {

  const { isMobile } = useResponsive();

  const typeNoti = useSelector(state => state.noti.type);
  const notiContent = useSelector(state => state.noti.content);

  const style = {
    alertContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      '&.MuiAlert-root': {
        fontSize: isMobile ? '.7em' : '.9em'
      },
      '&.MuiAlert-icon': {
        fontSize: isMobile ? '1.2em' : '1.5em'
      },
    },
    text: {
      fontWeight: 500,
      paddingLeft: '1em'
    }
  };

  return (
    <Alert sx={style.alertContainer} severity={typeNoti} onClose={closeCallback}>
      <p style={style.text}>{notiContent}</p>
    </Alert>
  );
};