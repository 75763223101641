import React from 'react';

import { useGeoLocation } from '../../hooks/useGeoLocation';


export const OptionButton = ({geolocation = false, label, icon, handleClick}) => {
  
  const {handleGeolocation} = useGeoLocation();

  return (
    <button className={geolocation ? 'highlight' : null} onClick={geolocation ? handleGeolocation : handleClick}>
      <span className={`icon icon-${icon}`}></span>
      <span>{label}</span>
    </button>
  );
};