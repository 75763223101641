import { config } from '../config';

const surveyApi = `${config.url.API_BASE_URL}/surveys`;
const surveyUserApi = `${config.url.API_BASE_URL}/users`;


export const createSurvey = async (data) => {
  const url = `${surveyApi}/`;
  const results = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(
      data
    ),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      localStorage.setItem('sondage_uuid', data.owner_uuid);
      return data;
      // Handle data
    })
    .catch((err) => {
      console.log('failed to create survey', err.message);
    });
  return results;
}; 

export const updateSurvey = async (userId, surveyId, data) => {
  const url = `${surveyUserApi}/${userId}/surveys/${surveyId}`;
  const results = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(
      data
    ),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((err) => {
      console.log('failed to update survey', err.message);
    });
  return results;
};