import React from 'react';

import { useDispatch } from 'react-redux';

import { getLabelCriteria } from '../hooks/useLabel';
import { setStepSurvey } from '../slices/surveySlice';

export const StepperSurvey = ({steps, currentStep}) => {

  const dispatch = useDispatch();

  const handleOnClickStepper = (step) => {
    if (currentStep === step) {
      return;
    } else {
      dispatch(setStepSurvey(step));
    }
  };
  return (
    <ol className='stepper'>
      {steps.map(elem => (
        <li 
          key={elem} 
          className={elem === currentStep ? 'current' : null} 
          onClick={() => handleOnClickStepper(elem)}>
          {getLabelCriteria(elem)}
        </li>
      ))}
    </ol>
  );
};