import React from 'react';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useDispatch, useSelector } from 'react-redux';

import { useResponsive } from '../hooks/useResponsive';
import { InfoPoiSelected } from '../components/InfoPoiSelected';
import { closeInfoMobile, openInfoMobile } from '../slices/poiSlice';

export const InfoPoiSelectedContainer = () => {

  const { isDesktop } = useResponsive();
  const dispatch = useDispatch();

  const expandedMobile = useSelector(state => state.poi.expandInfoMobile);

  const handleOpenExpand = () => {
    dispatch(openInfoMobile());
  };

  const handleCloseExpand = () => {
    dispatch(closeInfoMobile());
  };

  return (
    <React.Fragment>
      {isDesktop ? (
        <div className='overlay flow'>
          <InfoPoiSelected />
        </div>
      ) : (
        <SwipeableDrawer 
          open={true} 
          PaperProps={{
            className: 'overlay flow'
          }}
          anchor='bottom'
          onClose={handleCloseExpand}
          onOpen={handleOpenExpand}
          disableSwipeToOpen={false}
          
          variant={expandedMobile ? 'temporary' : 'permanent'}
        >
          <InfoPoiSelected 
            expanded={expandedMobile} 
            expandedCallback={handleOpenExpand}
          />
        </SwipeableDrawer>

      )}
    </React.Fragment>
  
  );
};