import React from 'react';

export const Presentation = () => {
  return (
    <div>
      <p>
        <b>La plateforme participative Ville Marchable vous permet de vous exprimer sur votre expérience quotidienne de la marche à pied dans les rues et chemins de votre ville.</b>
      </p>
      <br/>
      <p>
      Où aimez-vous marcher ? Qu’appréciez-vous en particulier ? L’aménagement des trottoirs, les rues et chemins bordés d’arbres, le calme ?
      Au contraire, où pensez-vous que le confort de marche ou la sécurité devraient être améliorés ?
      </p>
      <br/>
      <p>
        <b>Cela vous prendra 5 à 10 minutes pour noter quelques rues. Par exemple, 3 rues ou chemins que vous appréciez, et 3 qui sont à améliorer. Ou bien plus si vous le souhaitez !</b>
      </p>
      <br/>
      <p>
        <b>Il vous suffit de cliquer sur chaque rue ou chemin, puis d’indiquer votre avis sur chaque critère avec les smileys 🙂 et ☹️.</b>
      </p>
      <br/>
      <p>
      Votre participation est précieuse et aidera votre ville à établir de futures priorités d’aménagement bénéficiant au plus grand nombre.
      </p>
      
    </div>
  );
};