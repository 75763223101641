import React from 'react';

export const Condition = () => {
  return (
    <div className='personal-data'>
      <div className='personal-data-section'>
        <h2>
          Ce que nous faisons avec les données
        </h2>
        <p>
        Les données que nous collectons visent à améliorer la connaissance sur les conditions de marche, telles que ressenties par les habitants, ou visiteurs réguliers/occasionnels, d&apos;un territoire.
        </p>
        <p>
        Ces données ont donc vocation à être consultées de façon agrégéé, pour chaque tronçon de rue ou chemin, afin de mettre en lumière les critères les plus fréquemment remplis par les usagers, en fonction de leur âge, de leur genre ou leur handicap.
        </p>
        <p>
        Vos réponses individuelles ne seront donc consultées qu&apos;à des fins d&apos;amélioration du site et des outils d&apos;analyse des données par Someware. En revanche, elles ne seront pas consultables individuellement par les clients de Someware.
        </p>
      </div>
      <div className='personal-data-section'>
        <h2>
          Exercer vos droits
        </h2>
        <p className='cookie-explain'>
          Référent à la protection des données
        </p>
        <p>
        Les coordonnées du référent à la protection des données sont les suivantes :
        </p>
        <p>
        Bertrand GERVAIS<br/>
        Email : contact@someware.fr
        </p>
        <p className='cookie-explain'>
          Droit d’introduire une réclamation auprès de la Cnil
        </p>
        <p>
        Les clients et prospects concernés par le traitement de leurs données à caractère personnel sont informés de leur droit d’introduire une plainte auprès d&apos;une autorité de contrôle, à savoir la Cnil en France, si ces derniers estiment que le traitement de données à caractère personnel les concernant n&apos;est pas conforme à la règlementation européenne de protection des données, à l’adresse suivante :
        </p>
        <p>
        CNIL – Service des plaintes<br/>
        3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07<br/>
        Tél: 01 53 73 22 22
        </p>
      </div>
      <div className='personal-data-section'>
        <h2>
          À propos des cookies
        </h2>
        <p>
          Nous utilisons des <a href='https://www.cnil.fr/fr/definition/cookie' target='_blank' rel="noreferrer">cookies</a> (Mapillary) sur le site pour proposer des vues immersives des rues sélectionnées.
        </p>
        <div className='cookies-def'>
          <p className='cookies-def-headers'>
            Qu&apos;est-ce qu&apos;un &quot;cookie&quot; ?
          </p>
          <p>
          Un &quot;cookie&quot; est une suite d&apos;informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y re-connecterez. Les cookies ont de multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d&apos;un site marchand, le contenu courant de votre panier d&apos;achat, un identifiant permettant de tracer votre navigation pour des finalités statistiques ou publicitaires, etc.
          </p>
          <a href='https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur' target='_blank' rel="noreferrer">
            En savoir plus sur les cookies et leur fonctionnement
          </a>
        </div>
      </div>
      <div className='personal-data-section'>
        <h2>Type de cookie déposé sur le site</h2>
        <p className='cookie-explain'>
          Cookies strictement nécessaires au site pour fonctionner
        </p>
        <p>
          Ces cookies permettent aux services principaux du site de fonctionner de manière optimale. Vous pouvez techniquement les bloquer en utilisant les paramètres de votre navigateur mais votre expérience sur le site risque d’être dégradée.
        </p>
        <p>
          Par ailleurs, vous avez la possibilité de vous opposer à l’utilisation des traceurs de mesure d’audience strictement nécessaires au fonctionnement et aux opérations d’administration courante du site web dans la fenêtre de gestion des cookies en cliquant sur l&apos;image représentant un cookie en bas à gauche de l&apos;écran.
        </p>
      </div>
      <div className='personal-data-section'>
        <p className='cookies-technique-headers'>
          Cookies techniques
        </p>
        <table className='table-cookies'>
          <thead>
            <tr>
              <th>Nom du cookie	</th>
              <th>Finalité</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>mly_cb</td>
              <td>Aide à l&apos;analyse et à l&apos;amélioration du service de vues immersives Mapillary</td>
              <td>1 an</td>
            </tr>
          </tbody>
        </table>
        <p className='cookies-technique-headers'>
          Cookies de mesure d’audience
        </p>
        <table className='table-cookies'>
          <thead>
            <tr>
              <th>Nom du cookie	</th>
              <th>Finalité</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    </div>
  );
};