export const getGeolocationPermission = () => {
  if (navigator.permissions) {
    return navigator.permissions.query({name: 'geolocation'});
  } else {
    return new Promise((resolve) => {
      resolve({
        state: 'granted',
      });
    });
  }
};

