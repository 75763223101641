import { configureStore } from '@reduxjs/toolkit';

import mapReducer from './mapSlice';
import initReducer from './initSlice';
import poiReducer from './poiSlice';
import surveyReducer from './surveySlice';
import userReducer from './userSlice';
import searchReducer from './searchSlice';
import summaryReducer from './summarySlice';
import menuReducer from './menuSlice';
import notiReducer from './notiSlice';

export const store = configureStore({
  reducer: {
    map: mapReducer,
    init: initReducer,
    search: searchReducer,
    poi: poiReducer,
    survey: surveyReducer,
    summary: summaryReducer,
    user: userReducer,
    menu: menuReducer,
    noti: notiReducer
  }
});