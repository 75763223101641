import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  modalOpen: false,
  type: '',
  returnTo: '',
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.open = true;
    },
    closeMenu: (state) => {
      state.open = false;
    },
    openModal: (state, action) => {
      state.modalOpen = true;
      state.type = action.payload;
    },
    closeModal: (state) => {
      state.modalOpen = false;
      state.type = '';
    },
    setReturnTo: (state, action) => {
      state.returnTo = action.payload;
    }
  }
});

export const { 
  openMenu, 
  closeMenu,
  openModal,
  closeModal,
  setReturnTo
} = menuSlice.actions;

export default menuSlice.reducer;