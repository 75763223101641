import React from 'react';

import { useSelector } from 'react-redux';

import { ForwardButton } from '../components/Button/ForwardButton';
import { config } from '../config';


export const WelcomeContainer = ({forwardCallback}) => {
  const listSurveys = useSelector(state => state.user.surveyAnswers);
  const textIntro = useSelector(state => state.init.introduction);
  const cityName = useSelector(state => state.init.city);

  const someSurveysExecuted = Array.isArray(listSurveys) && listSurveys.length > 0; 

  const renderTitleWelcome = () => {
    if (someSurveysExecuted) {
      return 'Content de vous retrouver ! 👋';
    } else {
      return 'Bonjour 👋 ';
    }
  };

  const renderTextIntroduction = (introduction) => {
    if (typeof introduction == 'string') {
      const newText = introduction.split('/').map((elem, index) => (<p key={index}>{elem}</p>));
      return newText;
    } else {
      return '';
    }
  };

  const renderTextReturnWelcome = () => {
    return (
      <>
        <p>{`Nous vous remercions pour toutes vos contributions sur la qualité de marche de ${cityName}`}</p>
        <p>{`Vous pouvez poursuivre votre évaluation concernant la qualité de marche dans les rues et/ou chemins de ${cityName}.`}</p>
      </>
    );
  };

  return (
    <>
      <h1>
        <span className="hidden">Enquête participative sur la qualité de marche</span>
        <img src={`${config.url.API_BASE_URL}/logo`} alt="city-logo" />
      </h1>
      <div className="heading">{renderTitleWelcome()}</div>
      {someSurveysExecuted ? (
        renderTextReturnWelcome()
      ) : (
        renderTextIntroduction(textIntro)
      )}
      <ForwardButton label="C'est parti" handleClick={forwardCallback} />
    </>
  );
};