import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { openModal, setReturnTo } from '../slices/menuSlice';
import { closeSearch } from '../slices/searchSlice';
import { closeInfoPoi } from '../slices/poiSlice';
import { getLabelMenu } from '../hooks/useLabel';
import { getIconModalInfo } from '../utils/IconUtils';

const listMenuElem = ['presentation', 'link', 'contact', 'condition', 'legal'];

export const MenuContainer = ({open, onClose}) => {
  const dispatch = useDispatch();

  const searchShow = useSelector(state => state.search.open);
  const poiShow = useSelector(state => state.poi.open);

  const handleOpenModal = value => {
    if (searchShow) {
      dispatch(closeSearch());
      dispatch(setReturnTo('search'));
    } else if (poiShow) {
      dispatch(closeInfoPoi());
      dispatch(setReturnTo('poi'));
    }
    dispatch(openModal(value));
  };

  return (
    <div className={`map-menu ${open && 'active'} `}>
      <menu>
        {listMenuElem.map(elem => (
          <li key={elem}>
            <span className={`chipicon icon-${getIconModalInfo(elem)}`}></span>
   
            <a onClick={() => handleOpenModal(elem)} href="#">{getLabelMenu(elem)}</a>
          </li>
        ))}
      </menu>
      <button className="btn-close" onClick={onClose}>
        <span className="icon-times"></span>
      </button>
    </div>
  );
};