export const convertHandicapToValue = (handicap) => {
  switch (handicap) {
    case 'none': 
      return 'none';
    case 'hearing_impaired':
      return 'hearing';
    case 'mentally_impaired':
      return 'mentally';
    case 'visually_impaired':
      return 'visually';
    case 'wheelchair_impaired':
      return 'wheelchair';
    default:
      return null;
  }
};