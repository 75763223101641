import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Collapse  from '@mui/material/Collapse';

import { hideOptionsList, showOptionsList } from '../slices/searchSlice';
import { GeocoderInput } from '../components/GeocoderInput';
import { TypeSearchSelectContainer } from './TypeSearchSelectContainer';
import { OptionSearchContainer } from './Search/OptionSearchContainer';
import { resetPoi } from '../slices/poiSlice';
import { useGeoLocation } from '../hooks/useGeoLocation';
import { AlertNoti } from '../components/AlertNoti';
import { closeNoti } from '../slices/notiSlice';


export const SearchContainer = () => {

  const dispatch = useDispatch();

  const { handleGeolocation } = useGeoLocation();

  const showOptionsSearch = useSelector(state => state.search.showOptions);
  const labelSearch = useSelector(state => state.poi.labelPoi);
  const notiShow = useSelector(state => state.noti.open);
  const typeSearch = useSelector(state => state.search.type);

  const [options, setOptions] = useState([]);

  const handleOpenOptionList = () => {
    dispatch(showOptionsList());
  };

  const handleCloseOptionList = () => {
    dispatch(resetPoi());
    setOptions([]);
    dispatch(hideOptionsList());
  };

  const handleOptionsGeocoder = (optionsList) => {
    setOptions(optionsList);
  };

  const onCloseNoti = () => {
    dispatch(closeNoti());
  };

  useEffect(() => {
    if (notiShow) {
      setTimeout(() => {
        dispatch(closeNoti());
      }, [3000]);
    }
  }, [notiShow]);

  return (
    <div className='overlay flow'>
      <Collapse in={notiShow} collapsedSize={0} mountOnEnter unmountOnExit>
        <AlertNoti closeCallback={onCloseNoti} />
      </Collapse>
      {!showOptionsSearch && (
        <p>
          <strong>
          🎯 Déplacez-vous sur la carte et cliquez sur la rue/chemin que vous souhaitez évaluer
          </strong>
        </p>
      )}
      {!showOptionsSearch && (
        <div className="form-group">
          <div className="form-geoloc">
            <GeocoderInput 
              value={labelSearch}
              typeSearch={typeSearch}
              handleGeocoder={handleOptionsGeocoder} 
              handlePressInput={handleOpenOptionList}
            />
            <button className="geoloc" onClick={handleGeolocation}>
              <span className="icon-geoloc"></span>
            </button>
          </div>
        </div>
      )}
      {showOptionsSearch && (
        <div className="flex align-stretch gap-1">
          <button onClick={handleCloseOptionList} className="btn btn-empty btn-icon squeeze">
            <span className="icon-caret-left"></span>
          </button>     
          <div className="grow">
            <div className="form-group">
              <GeocoderInput 
                value={labelSearch}
                typeSearch={typeSearch}
                handleGeocoder={handleOptionsGeocoder} 
                autofocus={true}
              />
            </div>
          </div>
        </div>
      )}
      {showOptionsSearch && (
        <TypeSearchSelectContainer />
      )}
      {showOptionsSearch && (
        <OptionSearchContainer listOptions={options} />
      )}
    </div>
  );
};