import { config } from '../config';

export const geocoder = {
  search: async (q, type) => {
    const url = `${config.url.GEOCODER_URL}/q/${q}${type !== 'all' ? `?filter_type=${type}` : ''}`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
};