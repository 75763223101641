import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  showOptions: false,
  type: 'all', 
  loadOption: false
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    openSearch: (state) => {
      state.open = true;
    },
    closeSearch: (state) => {
      state.open = false;
    },
    showOptionsList: (state) => {
      state.showOptions = true;
    },
    hideOptionsList: (state) => {
      state.showOptions = false;
    },
    setTypeSearch: (state, action) => {
      state.type = action.payload;
    },
    setLoadOption: (state, action) => {
      state.loadOption = action.payload;
    }
  }
});

export const { 
  openSearch, 
  closeSearch, 
  showOptionsList,  
  hideOptionsList,
  setTypeSearch,
  setLoadOption
} = searchSlice.actions;

export default searchSlice.reducer;