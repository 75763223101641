import React from 'react';

import { ForwardButton } from '../../components/Button/ForwardButton';
import { BackwardButton } from '../../components/Button/BackwardButton';

export const FooterSurvey = ({
  steps, 
  stepEvaluation, 
  surveyWithComment,
  handleOnClickForward, 
  handleOnClickBackward
}) => {
  const renderButtonForward = () => {
    if (stepEvaluation === 'practicability' || stepEvaluation === 'comfort') {
      return (
        <ForwardButton 
          label={`Passer à l'étape suivante (${steps.indexOf(stepEvaluation) + 1}/3)`} 
          handleClick={handleOnClickForward}
        />
      );
    } else if (surveyWithComment) {
      return (
        <ForwardButton 
          label={`Passer à l'étape suivante (${steps.indexOf(stepEvaluation) + 1}/3)`} 
          handleClick={handleOnClickForward}
        />
      );
    } else {
      return (
        <button 
          form='survey-form'
          type='submit'
          className='btn btn-block btn-pill btn-primary btn-fill-h'
        >
          <span>Terminer</span>
          <span className='icon icon icon-arrow-right'></span>
        </button>
      );
    }
  };
  return (
    <footer className='survey-actions grid xs:grid-col-1 md:grid-col-12'>
      <div className='md:order-1 md:col-start-7 md:col-end-12'>
        {renderButtonForward()}
      </div>
      <div className='md:col-start-1 md:col-end-3'>
        <BackwardButton label={stepEvaluation === 'practicability' ? 'Fermer' : 'Retour'} handleClick={handleOnClickBackward}/>
      </div>
    </footer>
  );
};