import React from 'react';

import { useSelector } from 'react-redux';

import { Presentation } from './Presentation';
import { SendLink } from './SendLink';
import { Contact } from './Contact';
import { Condition } from './Condition';
import { Legal } from './Legal';
import { getIconModalInfo } from '../../utils/IconUtils';
import { getLabelMenu } from '../../hooks/useLabel';
import { useModal } from '../../hooks/useModal';

export const InfoModalContainer = () => {

  const {handleCloseModalMenu} = useModal();

  const typeInfo = useSelector(state => state.menu.type);
   

  const renderContent = () => {
    switch (typeInfo) {
      case 'presentation':
        return (
          <Presentation />
        );
      case 'link': 
        return(
          <SendLink closeModal={handleCloseModalMenu} />
        );
      case 'contact':
        return (
          <Contact />
        );
      case 'condition':
        return (
          <Condition />
        );
      case 'legal':
        return (
          <Legal />
        );
      default:
        return;
    }
  };

  return (
    <div className="overlay flow" style={{outline: 'none'}}>
      <div className='header-container'>
        <div className='header-label'>
          <span className={`chipicon icon-${getIconModalInfo(typeInfo)}`} style={{marginRight: '.5em'}}></span>
          <label className='label-modal'>{getLabelMenu(typeInfo)}</label>
        </div>
        <div>
          <button className="btn-close-menu" onClick={handleCloseModalMenu}>
            <span className="icon-times"></span>
          </button>
        </div>
      </div>
      {renderContent()}
    </div>
  );
};