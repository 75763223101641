import React from 'react';

export const Contact = () => {
  return (
    <div className='contact-container'>
      <div className='logo-section'>
        <img className='logo-someware' src={process.env.PUBLIC_URL + '/image/logo-someware.svg'} />
      </div>
      <div className='contact-info'>
        <div className='contact-section'>
          <span className='chipicon-contact icon-envelope'></span>
          <span>contact@someware.fr</span>
        </div>
        <div className='contact-section'>
          <span className='chipicon-contact icon-link'></span>
          <a href='https://www.someware.fr/' target='_blank' rel="noreferrer">
          https://www.someware.fr
          </a>
        </div>
      </div>
    </div>
  );
};