import React from 'react';

import { useDispatch } from 'react-redux';

import { BackwardButton } from '../../components/Button/BackwardButton';
import { SecondaryButton } from '../../components/Button/SecondaryButton';
import { closeSurvey, resetSurvey } from '../../slices/surveySlice';
import { startResetSelected } from '../../slices/mapSlice';
import { openSummary } from '../../slices/summarySlice';
import { setModify } from '../../slices/userSlice';
import { openSearch } from '../../slices/searchSlice';

export const UserFormFooter = ({handleOnClickBackward, modifyUserOnly}) => {

  const dispatch = useDispatch();

  const handleOnClickByPass = () => {
    if (modifyUserOnly) {
      dispatch(closeSurvey());
      dispatch(resetSurvey());
      dispatch(setModify(false));
      dispatch(openSearch());
    } else {
      dispatch(closeSurvey());
      dispatch(startResetSelected());
      dispatch(resetSurvey());
      dispatch(openSummary());
    }
  };

  return (
    <footer className={`survey-actions grid xs:grid-col-1 md:grid-col-${modifyUserOnly ? '2' : '3'}`}>
      <div className="md:order-2">
        <button 
          form='user-form'
          type='submit'
          className='btn btn-block btn-pill btn-primary btn-fill-h'
        >
          <span>Terminer</span>
          <span className='icon icon icon-arrow-right'></span>
        </button>
      </div>
      <div className="md:order-1">
        <SecondaryButton label={modifyUserOnly ? 'Annuler' : 'Passer cette étape'} handleClick={handleOnClickByPass} />
      </div>
      { !modifyUserOnly && (
        <div>
          <BackwardButton label='Retour' handleClick={handleOnClickBackward} />
        </div>
      )}
    </footer>
  );
};