import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ScrollDownButton = () => {

  const [show, setShow] = useState(true);


  const scrollDown = () => {
    window.scrollBy(0, 100);
  };

  useEffect(() => {
    const rect = document.getElementById('app');
    const checkIfBottom = () => {
      if (rect.getBoundingClientRect().bottom < window.innerHeight + 1) {
        setShow(false);
      } else {
        setShow(true);
      }
    };
    window.addEventListener('scroll', checkIfBottom);
    return () => {
      window.removeEventListener('scroll', checkIfBottom);
    };
  }, []);
  return (
    <button 
      className='btn-scroll' 
      style={{
        display: show ? 'flex' : 'none'
      }}
      onClick={scrollDown}>
      <ExpandMoreIcon className='icon-scroll' />
    </button>
  );
};