/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Collapse, Alert } from '@mui/material';

import { getLinkUnique } from '../../api/userApi';

export const SendLink = () => {

  const {register, handleSubmit, reset, formState: { errors }} = useForm();

  const [resultsType, setResultsType] = useState(undefined);
  const [resultsText, setResultsText] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const onSubmit = (data) => {
    getLinkUnique(data).then((response) => {
      const {results, message} = response;
      setResultsType(results);
      setResultsText(message);
      setOpenAlert(true);
      reset();
    });
  };

  const style = {
    alertContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      '&.MuiAlert-root': {
        fontSize: '.9em'
      },
      '&.MuiAlert-icon': {
        fontSize:  '1.2em'
      },
    },
    text: {
      fontWeight: 500,
      paddingLeft: '1em'
    }
  };

  return (
    <form  id='user-form' onSubmit={handleSubmit(onSubmit)}>
      <div className="form-input">
        <p>Après avoir noté une première rue ou chemin, nous vous proposons de nous indiquer votre adresse email afin de vous envoyer un lien unique vous permettant de revenir sur l&apos;enquête.</p>
        <label className='form-label'>
          <b>Si vous avez perdu votre lien unique, merci de saisir votre adresse e-mail pour le recevoir à nouveau :</b>
        </label>
        <input 
          style={{
            width: '100%', 
            height: 40, 
            borderRadius: '.5em', 
            marginTop: '1em', 
            marginBottom: '1em',
            paddingLeft: '.5em'
          }}
          className="form-text" 
          type="email" 
          name="email-return-link" 
          id="email-return-link"  
          {...register('email', {
            required: {
              value: true,
              message: 'Ce champs est requis'
            },
            pattern: {
              value:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Votre input ne correspond pas au format e-mail'
            }
          })}              
        />
        {errors.email && <p className='error-value'>{errors.email.message}</p>}
        <Collapse in={openAlert} collapsedSize={0} mountOnEnter unmountOnExit>
          <Alert 
            sx={style.alertContainer} 
            severity={resultsType}><p style={style.text}>{resultsText}</p></Alert>
        </Collapse>
        <footer className='survey-actions grid xs:grid-col-1 md:grid-col-12'>
          <div className='md:order-1 md:col-start-5 md:col-end-8'>
            <button 
              className='btn btn-block btn-pill btn-primary btn-fill-h'
              type='submit'
            >
              <span>Envoyer</span>
              <span className='icon icon icon-arrow-right'></span>
            </button>
          </div>
        </footer>
      </div>
    </form>
  );
};