import React from 'react';

export const Legal = () => {
  return (
    <div className='legal-note'>
      <div className='info-legal'>
        <h2>Editeur</h2>  
        <p>
          Ville Marchable est édité par SOMEWARE, Société par actions simplifiée au capital de 20 000,00 €.
        </p>
        <p>
          Siège: 11 Rue Julien Gracq 35690 ACIGNE, FRANCE
        </p>
        <p>
          SIREN: 853 971 125.
        </p>
        <p>
          Numéro TVA: FR21853971125
        </p>
        <p>
          Email: contact@someware.fr
        </p>
      </div>
      <div className='info-legal'>
        <h2>Hébergement</h2>  
        <p>
          Ikoula – 177 Rue d’Aguesseau, 92100 Boulogne-Billancourt – www.ikoula.com
        </p>
      </div>      
    </div>
  );
};