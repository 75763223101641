import React from 'react';

import { BackwardButton } from '../../components/Button/BackwardButton';

export const FooterSurveySupp = ({handleOnClickBackward}) => {
  return (
    <footer className="survey-actions grid xs:grid-col-1 md:grid-col-12">
      <div className="md:order-2 md:col-start-10 md:col-end-12">
        <button 
          form='survey-form'
          type='submit'
          className='btn btn-block btn-pill btn-primary btn-fill-h'
        >
          <span>Terminer</span>
          <span className='icon icon icon-arrow-right'></span>
        </button>
      </div>
      <div className="md:col-start-1 md:col-end-3">
        <BackwardButton label='Retour' handleClick={handleOnClickBackward} />
      </div>
    </footer>
  );
};