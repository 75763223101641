import { useDispatch, useSelector } from 'react-redux';
import { useMap } from 'react-map-gl/maplibre';
import bboxPolygon from '@turf/bbox-polygon';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point } from '@turf/helpers';

import { getGeolocationPermission } from '../utils/geoLocationUtils';
import { openNoti, setContentNoti, setTypeNoti } from '../slices/notiSlice';

export const useGeoLocation = () => {
  const MIN_ZOOM = 16;
  
  const dispatch = useDispatch();
  const litmitBounds = useSelector(state => state.map.limitBounding);

  const { map } = useMap();

  const handleGeolocation = () => {
    getGeolocationPermission().then((results) => {
      if(results.state === 'denied') {
        console.log('no value user location');
      } else {
        navigator.geolocation.getCurrentPosition( pos => {
          const value = {longitude: pos.coords.longitude, latitude: pos.coords.latitude};
          if (map && litmitBounds.length > 0) {
            const limitPolygon = bboxPolygon(litmitBounds);
            const center = point([value.longitude, value.latitude]);
            if (booleanPointInPolygon(center, limitPolygon)) {
              map.easeTo({
                center: [value.longitude, value.latitude],
                zoom: MIN_ZOOM,
                duration: 1000
              });
            } else {
              dispatch(setContentNoti('Vous êtes en dehors de la couverture du sondage'));
              dispatch(setTypeNoti('error'));
              dispatch(openNoti());
            }
          }
        }, (error) => {
          if(error.code == 1) {
            // Error: Access is denied!
          } else if( error.code == 2) {
            // "Error: Position is unavailable!"
          }
        });
      }
    });
  };

  return { handleGeolocation };
};