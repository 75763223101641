import React from 'react';

export const ForwardButton = ({label, handleClick}) => {
  return (
    <button 
      className='btn btn-block btn-pill btn-primary btn-fill-h'
      onClick={handleClick}
    >
      <span>{label}</span>
      <span className='icon icon icon-arrow-right'></span>
    </button>
  );
};