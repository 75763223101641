import React, { forwardRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { closeSurvey, resetSurvey, setStepSurvey, setSurvey, setTypeSurvey } from '../slices/surveySlice';
import { openInfoPoi, resetPoi } from '../slices/poiSlice';
import { FooterSurvey } from './Evaluation/FooterSurvey';
import { FooterSurveySupp } from './Evaluation/FooterSurveySupp';
import { FormSurveyUserInfoContainer } from './Evaluation/FormSurveyUserInfoContainer';
import { startResetSelected } from '../slices/mapSlice';
import { openSearch } from '../slices/searchSlice';
import { FormSurvey } from './FormSurvey';
import { scrollWindowToTop } from '../utils/screenUtils';
import { ScrollDownButton } from '../components/Button/ScrollDownButton';
import { useResponsive } from '../hooks/useResponsive';

const SurveyFormContainer = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { isDesktop } = useResponsive();

  const typeSurvey = useSelector(state => state.survey.type);
  const stepEvaluation = useSelector(state => state.survey.step); 
  const surveyResults = useSelector(state => state.survey.surveyRes);
  const surveyWithComment = useSelector(state => state.init.withComment);
  const surveyId = useSelector(state => state.survey.surveyId);
  const userListSurveys = useSelector(state => state.user.surveyAnswers);

  const steps = ['practicability', 'comfort', 'security'];

  const handleMoveInSurvey = value => {
    dispatch(setStepSurvey(value));
  };



  const returnToInfoPoi = () => {
    dispatch(closeSurvey());
    dispatch(openInfoPoi());
  };


  const handleOnClickForward = () => {
    scrollWindowToTop();
    switch (stepEvaluation) {
      case 'practicability':
        handleMoveInSurvey('comfort');
        break;
      case 'comfort':
        handleMoveInSurvey('security');
        break;
      case 'security': 
        if (surveyWithComment) {
          handleMoveInSurvey('comment');
        }
        break;
      default:
        break;
    }
  };

  const handleOnClickBackward = () => {
    scrollWindowToTop();
    switch (stepEvaluation) {
      case 'practicability':
        if (typeSurvey === 'update') {
          dispatch(closeSurvey());
          dispatch(resetSurvey());
          dispatch(startResetSelected());
          dispatch(resetPoi());
          dispatch(openSearch());
        } else {
          dispatch(resetSurvey());
          returnToInfoPoi();
        }
        break;
      case 'comfort':
        handleMoveInSurvey('practicability');
        break;
      case 'security': 
        handleMoveInSurvey('comfort');
        break;
      case 'comment':
        handleMoveInSurvey('security');
        break;
      case 'userInfo':
        if (surveyId && surveyId !== null) {
          dispatch(setTypeSurvey('update'));
          const surveySelected = userListSurveys.find(elem => elem.id === surveyId);
          dispatch(setSurvey(surveySelected));
        }
        if (surveyWithComment) {
          handleMoveInSurvey('comment');
        } else {
          handleMoveInSurvey('security');
        }
        break;
      default:
        break;
    }
  };


  const renderFigure = () => {
    switch (stepEvaluation){
      case 'practicability':
        return 'pan-left';
      case 'security':
        return 'pan-right';
      default:
        return '';
    }
  };

  return (
    <div ref={ref} className='overlay h-full has-banner banner-sm'>
      {steps.indexOf(stepEvaluation) !== -1 ? (
        <figure className={`banner ${renderFigure()} md:hidden`}>
          <img src={process.env.PUBLIC_URL + '/image/people-bg.png'} alt="people-bg" />
        </figure>
      ) : (
        <figure className="banner faded cover md:hidden">
          <img src={process.env.PUBLIC_URL + '/image/people-extra-bg.png'} alt="people-extra-bg" />
        </figure>

      )}
      <div className='content'>
        {stepEvaluation !== 'userInfo' ? (
          <FormSurvey 
            steps={steps}
            stepEvaluation={stepEvaluation}
            surveyResults={surveyResults}
          />
        ) : (
          <FormSurveyUserInfoContainer
            handleOnClickBackward={handleOnClickBackward}
          />
        )}
        {steps.indexOf(stepEvaluation) !== -1 && (
          <FooterSurvey 
            steps={steps} 
            stepEvaluation={stepEvaluation} 
            surveyWithComment={surveyWithComment}
            handleOnClickBackward={handleOnClickBackward}
            handleOnClickForward={handleOnClickForward}
          />
        )}
        {
          stepEvaluation === 'comment' &&  (
            <FooterSurveySupp
              handleOnClickBackward={handleOnClickBackward}
            />
          )
        }
        {!isDesktop && (
          <ScrollDownButton />
        )}
      </div>
    </div>
  );
});
SurveyFormContainer.displayName = 'SurveyFormContainer';

export default SurveyFormContainer;