import { config } from '../config';

const configApi = `${config.url.API_BASE_URL}/config`;

export const getConfigData = async () => {
  const url = `${configApi}`;
  const results = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }) .then((response) => response.json())
    .catch((err) => {
      console.log('failed to get info user', err.message);
    });
  return results;
};