import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { getClassIconTypeSearch } from '../utils/IconUtils';
import { setTypeSearch } from '../slices/searchSlice';

const listTypeSearch = [
  {id: 'all', label: 'Tout'},
  {id: 'way', label: 'Rue'},
  {id: 'station', label: 'Arrêts'},
  {id: 'parking', label: 'Parkings'},
  {id: 'other', label: 'Autres'},

];

export const TypeSearchSelectContainer = () => {

  const dispatch = useDispatch();
  const typeSearch = useSelector(state => state.search.type);


  return (
    <menu className='button-list'>
      {listTypeSearch.map(elem => (
        <li key={elem.id}>
          <button 
            onClick={() => dispatch(setTypeSearch(elem.id))}
            className={elem.id === typeSearch ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'}
          >
            <span className={`icon icon-${getClassIconTypeSearch(elem.id)}`}></span>
            <span>{elem.label}</span>
          </button>
        </li>
      ))}
    </menu>
  );

};