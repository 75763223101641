import React from 'react';
import { useResponsive } from '../hooks/useResponsive';

export const LogoMarchable = () => {
  const { isDesktop } = useResponsive();
  return (
    <div className='logo-ville-marchable'>
      <img className='logo' src={process.env.PUBLIC_URL + `/image/logo-ville-marchable-${isDesktop ? 'ligne' : 'bloc'}.svg`} alt="logo-marchable" />
    </div>
  );
};