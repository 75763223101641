import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../slices/menuSlice';
import { openSearch } from '../slices/searchSlice';
import { openInfoPoi } from '../slices/poiSlice';
import { closeWelcome } from '../slices/initSlice';
import { openSurvey } from '../slices/surveySlice';

export const useModal = () => {
  const dispatch = useDispatch();

  const stepToReturn = useSelector(state => state.menu.returnTo);

  const handleCloseModalMenu = () => {
    dispatch(closeModal()); 
    if (stepToReturn === 'search') {
      dispatch(openSearch());
    } else if (stepToReturn === 'poi') {
      dispatch(openInfoPoi());
    } else if (stepToReturn === 'survey') {
      dispatch(openSurvey());
    }
  };    

  const handleCloseModalSelectNeighbor = () => {
    dispatch(closeWelcome());
    dispatch(openSearch());
  };

  return {
    handleCloseModalMenu,
    handleCloseModalSelectNeighbor
  };
};