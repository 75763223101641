import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  type: 'create',
  step: 'practicability',
  surveyId: null,
  surveyRes: {
    id: null,
    sidewalks: null,
    crossings: null,
    accessibility: null,
    slope: null,
    obstruction: null,
    shops: null,
    stops: null,
    aesthetic: null,
    vegetation: null,
    cleanness: null,
    noise: null,
    crowded: null,
    lighting: null,
    seat: null,
    shaded: null,
    cars: null,
    bikes: null,
    safety: null,
    owner_uuid: null,
    ways: [],
    comment: ''
  },
};

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    openSurvey: (state) => {
      state.open = true;
    },
    closeSurvey: (state) => {
      state.open = false;
    },
    setStepSurvey: (state, action) => {
      state.step = action.payload;
    },
    setTypeSurvey: (state, action) => {
      state.type = action.payload;
    },
    resetSurvey: () => initialState,
    setSurveyId: (state, action) => {
      state.surveyId = action.payload;
    },
    setSurvey: (state, action) => {
      state.surveyRes = action.payload;
    },
    resetCriteria: (state, action) => {
      state.surveyRes = {...state.surveyRes, [action.payload]: null};
    },
    setCommentSurvey: (state, action) => {
      state.surveyRes = {...state.surveyRes, comment: action.payload};
    },
    setWaysToSurvey: (state, action) => {
      state.surveyRes = {...state.surveyRes, ways: action.payload};
    }
  }
});

export const { 
  openSurvey, 
  closeSurvey, 
  setWaysToSurvey,
  setStepSurvey,
  setTypeSurvey,
  resetSurvey,
  setSurveyId,
  setSurvey,
  resetCriteria,
  setCommentSurvey
} = surveySlice.actions;

export default surveySlice.reducer;