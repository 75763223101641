import { callGetWayJson } from '../api/FeatureApi';

export const setSelectedGeoJson = (wayId) => {
  const results = callGetWayJson(wayId).then(feature => {
    return  {
      type: 'Feature',
      properties: {
        fid: feature.id,
        osmid: feature.osmid,
        name: feature.name,
      },
      geometry: JSON.parse(feature.geom),
    };
  }).catch(error => {
    console.log('error set selected geojson', error);
    return {};
  });
  return results;
};

export const setSelectedFromEvalGeoJson = (surveyId, wayId) => {
  const results = callGetWayJson(wayId).then(feature => {
    return  {
      type: 'Feature',
      properties: {
        fid: feature.id,
        osmid: feature.osmid,
        name: feature.name,
        survey: surveyId
      },
      geometry: JSON.parse(feature.geom),
    };
  }).catch(error => {
    console.log('error set selected eval geojson', error);
    return {};
  });
  return results;
};