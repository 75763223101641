export const getClassIconTypeSearch = type => {
  switch (type) {
    case 'all':
      return 'map';
    case 'street':
      return 'pin';
    case 'station':
      return 'bus';
    case 'parking':
      return 'parking';
    case 'other':
      return 'pin';
    case 'my_location':
      return 'geoloc';
    default:
      return 'pin';
  }

};

export const getIconStepSurvey = step => {
  switch (step) {
    case 'practicability':
      return 'icon-walk';
    case 'comfort': 
      return 'icon-city';
    case 'security':
      return 'icon-shield';
    case 'comment':
      return 'icon-info';
    default:
      return null;
  }
};

export const getIconModalInfo = info => {
  switch (info) {
    case 'presentation':
      return 'hand';
    case 'link':
      return 'link';
    case 'contact':
      return 'phone';
    case 'condition':
      return 'sign';
    case 'legal':
      return 'balance';
    default:
      return;
  }
};