import { config } from '../config';

export const getImageStreetId = async (bbox) => {
  const url = `${config.url.GRAPH_MAPILLARY_URL}/images?bbox=${bbox}&limit=1`;
  const results = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'Authorization': 'OAuth MLY|6775241895922391|0d8bb0fe1626b0804a775875b9f30518'
    },
  }) .then((response) => response.json())
    .catch((err) => {
      console.log('failed to get info user', err.message);
    });
  return results;
};