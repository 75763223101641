import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';

import { WelcomeContainer } from './WelcomeContainer';
import { NeighborhoodSelectContainer } from './NeighborhoodSelectContainer';
import { setStep } from '../slices/initSlice';
import { useResponsive } from '../hooks/useResponsive';
import { useModal } from '../hooks/useModal';

export const InitContainer = ({initShow}) => {
  const dispatch = useDispatch();
  const {isMobile} = useResponsive();
  const { handleCloseModalSelectNeighbor } = useModal();

  const stepInit = useSelector(state => state.init.step);
  const zones = useSelector(state => state.map.zones);

  const style = {
    modalStyle: {
      '&.MuiModal-root': {
        top: isMobile ? 'unset' : 0
      }
    }
  };

  const forwardCallBackWelcome = () => {
    if (zones.length > 0) {
      dispatch(setStep('search'));
    } else {
      handleCloseModalSelectNeighbor();
    }
  };

  const onCloseModal = () => {
    if (stepInit === 'search') {
      handleCloseModalSelectNeighbor();
    } else {
      return;
    }
  };

  const renderContent = () => {
    if (stepInit === 'welcome') {
      return (
        <div className="overlay flow has-banner" tabIndex={0} style={{outline: 'none'}}>
          <figure className="banner pan-left">
            <img className='image-people-bg' src={process.env.PUBLIC_URL + '/image/people-bg.png'} alt="logo-ville" />
          </figure>
          <WelcomeContainer 
            forwardCallback={forwardCallBackWelcome}
          />
        </div>
      );
    } else {
      return (
        <div className="overlay flow" style={{outline: 'none'}}>
          <NeighborhoodSelectContainer />
        </div>
      );
    }
  };

  return (
    <Modal 
      open={initShow}  
      sx={style.modalStyle}
      onClose={onCloseModal}
    >
      {renderContent()}
    </Modal>
  );
};