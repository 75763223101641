import React, { useCallback } from 'react';

import {useMap} from 'react-map-gl/maplibre';

export const MapControl = () => {
  const { map } = useMap();

  const handleZoomIn = useCallback(() => {
    map.getMap().zoomIn({duration: 500});
  }, []);

  const handleZoomOut = useCallback(() => {
    map.getMap().zoomOut({duration: 500});

  });

  return (
    <div className='zoom-menu'>
      <div className="btn-group shadow-md">
        <button className="btn btn-icon" onClick={handleZoomIn}>
          <span className="icon icon-plus"></span>
        </button>
        <button className="btn btn-icon" onClick={handleZoomOut}>
          <span className="icon icon-minus"></span>
        </button>
      </div>
    </div>
  );
};