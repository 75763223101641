import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  show: false,
  modify: false,
  uuid: null,
  email: null,
  age: null,
  gender: null,
  handicap: null,
  consent: false,
  consent_email: false,
  consent_handicap: false,
  consent_age: false,
  surveyAnswers: []
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    showInfoUser: (state) => {
      state.show = true;
    },
    closeInfoUser: (state) => {
      state.show = false;
    },
    setModify: (state, action) => {
      state.modify = action.payload;
    },
    setUserId: (state, action) => {
      state.uuid = action.payload;
    },
    setUserEmail: (state, action) => {
      state.email = action.payload;
    },
    setUserAge: (state, action) => {
      state.age = action.payload;
    },
    setUserHandicap: (state, action) => {
      state.handicap = action.payload;
    },
    setUserGender: (state, action) => {
      state.gender = action.payload;
    },
    setUserSurvey: (state, action) => {
      state.surveyAnswers = action.payload;
    },
    setUserConsent: (state, action) => {
      state.consent = action.payload;
    },
    setUserConsentEmail: (state, action) => {
      state.consent_email = action.payload;
    },
    setUserConsentAge: (state, action) => {
      state.consent_age = action.payload;
    },
    setUserConsentHandicap: (state, action) => {
      state.consent_handicap = action.payload;
    },
    resetUser: () => initialState
  }
});

export const { 
  showInfoUser, 
  closeInfoUser,
  setModify,
  setUserId,
  setUserAge,
  setUserGender,
  setUserEmail,
  setUserHandicap,
  setUserSurvey,
  setUserConsent,
  setUserConsentAge,
  setUserConsentEmail,
  setUserConsentHandicap,
  resetUser
} = userSlice.actions;

export default userSlice.reducer;